.card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 33.33333%));
  gap: 1rem;
  justify-content: center;
  margin: 2rem auto;
  max-width: 100%;
 
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 3px solid #ccc;
  border-radius: 7px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
  max-width: 1000px; /* added to limit the width of the card */
}
  
  .card:hover {
    transform: translateY(-5px);
  }
  
  .card img {
    max-width: 100%;
    height: auto;
    margin-bottom: 1rem;
  }
  
  .card h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  
  