.navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    background-color: rgb(10, 71, 104);
    padding: 10px;
    z-index: 1;
    display: flex;
    
    
  }
  
  .navbar a {
    color: white;
    text-decoration: none;
    margin-left: 20px;
  }
  
  .navbar a:hover {
    color: rgb(114, 6, 6);
  }
  