.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 10s linear;
  }
}

.App-header {
  background-color: #0e2332;
  min-height: 10vh;
  width: 100%;
  display: flex;
  flex-direction: c;
  align-items: center;
  justify-content: center;
  font-size: calc(60px + 2vmin);
  color: white;
}

#fade-in-text {
  opacity: 0;
  transition: opacity 1s;
}

.fade-in {
  opacity: 0; /* make the element invisible */
  animation-name: fade-in; /* specify the name of the animation */
  animation-duration: 1s; /* duration of the animation */
  animation-delay: 2s; /* delay before the animation starts */
  animation-fill-mode: forwards; /* keep the element visible after the animation ends */
}

@keyframes fade-in {
  from { opacity: 0; } /* starting opacity */
  to { opacity: 1; } /* ending opacity */
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes flashy {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: scale(1.2);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.flashy-text {
  animation: flashy 2s ease-out;
}

.main-content {
  background: linear-gradient(to bottom, #0e2332, black);
  color: white;
  padding: 0px;
  font-size: 18px;
  line-height: 1.5;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  height:auto;
  
  
}


.main-content h1 {
  margin-bottom: 16px;
  font-size: 50px;
  
}

.main-content p2 {
  font-size: 40px;
  min-height: 60px;
  display:inline-block;
  margin-bottom: 16px;
  border-right: 2px solid #fff;
  white-space: nowrap;
  overflow: visible;
  width: 1;
  animation: typing 4s steps(40, end),
             blink-caret .75s step-end infinite;
}

.field-items {
  padding-left: 30px;
}







