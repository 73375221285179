.modal {
    display: block;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
}



.modal-content {
    background-color: #000000;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    min-height:300px;
    max-width: 1000px;
    text-align: center;
    position: relative;
}

.modal-content p {
    white-space: pre-line;
  }

.close {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 28px;
    font-weight: bold;
    color: #aaa;
    padding: 5px;
    cursor: pointer;
}